<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "@/firebase/db";
window.onbeforeunload = function () {
  if (performance.navigation.type != performance.navigation.TYPE_RELOAD)
    firebase.auth().signOut();
};
export default {
  name: "App",
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import "@/assets/global-styles.scss";
#app {
  color: $primary;
}
body {
  font-family: "Source Sans Pro", sans-serif;
  word-wrap: wrap;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
}
.v-btn {
  width: 50%;
  padding: 20px;
  text-transform: unset !important;
}
label {
  font-weight: 600;
}
.cursor {
  cursor: pointer;
}
.v-btn__content {
  font-weight: 600;
}

.bold-text {
  font-weight: bold;
}

.thin-text {
  font-weight: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
  width: 100%;
}
</style>
