<template>
  <div class="not-found">
    <div>
      <h1>404</h1>
      <h2>Side ikke funnet</h2>
    </div>
    <div>
      <p>Prøv en av disse sidene:</p>
      <div class="container">
        <button @click="$router.go(-1)">Gå tilbake</button>
        <button @click="$router.push({ name: 'Home' })">Gå hjem</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  height: 80%;
}
h1,
h2 {
  font-size: 5em;
}

.container {
  display: flex;
  justify-content: space-evenly;
}
</style>