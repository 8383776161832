import Vue from 'vue';
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
      themes: {
        light: {
          primary: '#046B5F',
          secondary: '#F0E3DE',
          accent: '#046B5F',
          error: '#b71c1c',
          success: '#4CAF50',
          warning: '#FB8C00',
          background: '#F3F1EF',
        },
      },
    },
    icons: {
      iconfont: 'mdiSvg',
    }
  }) 