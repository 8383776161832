<template>
  <v-container class="fill-height">
    <v-row class="text-center flex-column">
      <v-col class="pa-10">
        <img
          class="logo"
          src="../assets/img/caretag-logo.svg"
          alt=""
          srcset=""
        />
        <v-col class="secondary--text subtitle-2">THE CARETAG</v-col>
      </v-col>

      <v-col class="pt-16">
        <v-btn
          rounded
          outlined
          color="secondary"
          class="pa-7 primary--text"
          @click="$router.push({ name: 'CreateUser' })"
          >Opprett bruker</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          rounded
          color="secondary"
          class="pa-7 primary--text"
          @click="checkCreds"
          >Logg inn</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-center flex-column">
      <v-footer padless color="primary">
        <v-col class="text-center secondary--text subtitle-2"
          >© Ilda Care AS</v-col
        >
      </v-footer>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "@/firebase/db";
export default {
  name: "Home",
  methods: {
    async checkCreds() {
      // var currentUser = firebase.auth().currentUser;
      // if (currentUser) {
      //   this.$router.push("/min-profil");
      // } else {
        this.$router.push({ name: "Login" });
      // }
    },
    async login() {
      let user;
      let provider = new firebase.auth.GoogleAuthProvider();
      await firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          user = result.user;
          // let token = result.credential.accessToken;
          // console.log(token); // Token
          // console.log(user); // User that was authenticated

          // Tlf. melding først.
          this.$router.push("/min-profil");
          console.log(user.uid);
        })
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
        });
      var docRef = firebase.firestore().collection("user").doc(user.uid);
      console.log(docRef);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";


.container {
  background: $primary;
}
.v-btn::before {
  background: $secondary;
}
</style>
