<template>
  <v-dialog
    fullscreen
    hide-overlay
    v-model="dialog"
    transition="dialog-bottom-transition"
  >
    <div class="v-card-wrapper">
      <v-card class="v-card">
        <v-card-title class="headline primary background--text centered-text">
          Vilkår for bruk
        </v-card-title>
        <v-card-text class="primary--text">
          <h2>Brukervilkår The CareTag app for web</h2>
          <p>Oppdatert Mai 2022</p>
        </v-card-text>
        <v-card-text
          class="primary--text"
          v-for="(terms, index) in termsOfService"
          :key="index"
        >
          <h3>{{ index + 1 }} {{ terms.title }}</h3>
          <p>
            {{ terms.text }}
            <a target="_blank" :href="terms.link" v-if="terms.link"
              >{{ terms.link }}.</a
            >
          </p>
          <ul v-if="terms.listItems">
            <li v-for="(item, i) in terms.listItems" :key="i">{{ item }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="removeDialog()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      termsOfService: [
        {
          title: "Innledning",
          text: "Vår app er laget for å brukes sammen med ditt CareTag-smykke. Vårt mål er å bidra til at viktig og nødvendig informasjon om deg blir tilgjengelig for andre i krisesituasjoner slik at andre kan hjelpe deg bedre. Vår app er en app for web. Webappen kan brukes gjennom nettleser og krever ikke smarttelefon.Utgiver av appen er Ilda Care AS, org. no. 923 709 037, postadresse c/o Camilla Johansen Victor, Stogata 21B, 3181 Horten, post@thecaretag.no.Les gjennom brukervilkårene før du tar appen i bruk. Ved å klikke på «opprett konto» aksepterer du brukervilkårene.",
          link: "",
          listItems: [],
        },
        {
          title: "Om bruk av appen",
          text: "Vår app er laget for bruk sammen med ditt CareTag-smykke. Vår app er en frivillig tilleggstjeneste.Vår app er laget for at du skal kunne registrere opplysninger om deg selv og eventuelt pårørende slik at andre skal kunne lese opplysningene i et krisetilfelle. Opplysningene du velger å legge inn i vår tjeneste vil være leselig for andre som har tilgang til QR-koden i ditt CareTag-smykke. Du kan når som helst endre eller slette opplysninger i tjenesten. Dette kan du gjøre ved å logge deg på din konto hos oss.Vi ber deg tenke nøye igjennom før du registrerer informasjon om deg selv og eventuelt pårørende i vår tjeneste. Vi understreker igjen at informasjon du gir vil være tilgjengelig for personer som treffer deg i en krisesituasjon. Dersom du gir informasjon om pårørende i vår tjeneste  ber vi deg informere dine pårørende om dette.Vi behandler personopplysninger for å kunne levere tjenesten. Se vår personvernerklæring for mer informasjon om vår behandling av personopplysninger.For å ta vår app i bruk må du opprette en brukerkonto hos oss.",
          link: "",
          listItems: [],
        },
        {
          title: "Tilgangstillatelser",
          text: "For smarttelefonen vil du bli bedt om å gir vår app tillatelser. Det er frivillig for deg å gi slike tillatelser, men dersom du avslår kan dette medfører at funksjonaliteten reduseres. Vår app vil kunne be om følgende tillatelser:",
          link: "",
          listItems: [
            "kamera",
            "motta data fra internett",
            "nettverksadgang",
            "push varsler",
            "bilder lagret på enheten",
          ],
        },
        {
          title: "Avtaleperiode og opphør av tjeneste",
          text: "Vi tilbyr deg bruk av vår app uten betaling. Vår app er en tilleggstjeneste til våre CareTag-smykker. Brukervilkårene gjelder så lenge du har en brukerkonto hos oss.  Du kan når som helst avslutte din konto og slette alle opplysninger du har lagret i vår tjeneste.Vi vil kunne avslutte din tilgang til tjenesten ved misbruk eller klare brudd på brukervilkårene.  ",
          link: "",
          listItems: [],
        },
        {
          title: "Immaterielle rettigheter og tillatelser",
          text: "Vi gir deg en begrenset, ikke-eksklusiv, ikke-overførbar, royalty-fri, verdensomspennende bruksrett til å bruke vår app på alle enheter som du eier eller kontrollerer. Bruksretten gis under forutsetning av at du godtar brukervilkårene. Vår app inneholder informasjon og material som eies av Ilda Care AS og som er beskyttet av gjeldende immaterialrettslig lovgivning. Dette gjelder blant annet all opphavsrett til tekst, programvare, grafikk, design og alle rettigheter til varemerker. Bortsett fra der det etter brukervilkårene er uttrykkelig tillatt skal informasjon og materiale ikke",
          link: "",
          listItems: [
            "brukes til andre formål enn det som samsvarer med disse brukervilkårene,",
            "kopieres eller reproduseres i noen form, eller",
            "endres, leies ut, selges, distribueres eller utnyttes kommersielt.",
            "Du erverver ingen immaterielle rettigheter til appen utover den begrensede bruksretten vi gir deg.",
            "Informasjon du velger å lagre i vår tjeneste, er samtidig din eiendom. ",
          ],
        },
        {
          title: "Ansvar",
          text: "Vi tilbyr deg vår app «som den er» og det gis ingen garantier for at funksjonaliteten eller innholdet i våre tjenester er feilfrie. Vi kan heller ikke garantere at vår tjeneste vil leveres til evig tid. Vi garanterer heller ikke oppetid, og mindre driftsavbrudd kan fra tid til annen forekomme. Vi vil naturligvis gjøre vårt beste for å sikre en stabil og brukervennlig tjeneste. Du er selv ansvarlig for din bruk av appen.",
          link: "",
          listItems: [],
        },
        {
          title: "Endringer i tjenesten",
          text: "Vi vil fra tid til annen gjøre endringer i våre apper ved å legge til eller fjerne funksjonalitet.",
          link: "",
          listItems: [],
        },
        {
          title: "Lovvalg og verneting",
          text: "Disse brukervilkårene er underlagt norsk lov. Eventuelle tvister i forbindelse med vilkårene skal avgjøres av norske domstoler med mindre tvingende forbrukervernregler leder til noe annet. ",
          link: "",
          listItems: [],
        },
        {
          title: "Konfliktløsning",
          text: "Klager rettes til selger innen rimelig tid, jf. punkt 9 og 10. Partene skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke lykkes, kan kjøperen ta kontakt med Forbrukerrådet for mekling. Forbrukerrådet er tilgjengelig  på  telefon 23  400  500  eller ",
          link: "www.forbrukerradet.no",
          listItems: [],
        },
      ],
    };
  },
  methods: {
    removeDialog() {
      this.$emit("closeToS");
    },
  },
};
</script>

<style scoped>
.v-card-wrapper {
  height: fit-content;
}

.v-card {
  height: 100%;
}

.centered-text {
  justify-content: center;
}

.primary--text {
  padding: 15px 30%;
}

.v-btn {
  width: 100%;
}
</style>
