<template>
  <div class="text-center">
    <v-dialog fullscreen hide-overlay v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline primary--text lighten-2">
          Verifiser e-mail
        </v-card-title>
        <v-card-text class="primary--text">
          Vennligst verifiser emailen vi sendte til:
          <br />
          {{ email }}
          <br />
          Sjekk søpplepost hvis du ikke finner meldingen.
        </v-card-text>
        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn color="primary" text @click="removeDialog()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import firebase from "@/firebase/db";
export default {
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    email: String,
  },
  methods: {
    removeDialog() {
      var currentUser = firebase.auth().currentUser;
      currentUser.sendEmailVerification();
      this.dialog = false;
      this.$emit("closeEmailPrompt");
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 800px) {
  .v-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    justify-self: center;
    padding: 30px;
  }
}
.v-btn {
  width: initial;
}
</style>