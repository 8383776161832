<template>
  <v-container>
    <v-app-bar color="primary" class="background--text" height="80px" flat app>
      <v-toolbar-title class="ma-auto subtitle-2">THE CARETAG</v-toolbar-title>
    </v-app-bar>

    <v-row class="text-center flex-column">
      <v-col>
        <v-avatar class="text-center" color="primary" size="120">
          <img
            v-if="profileInfo.avatar"
            :src="profileInfo.avatar"
            width="100"
            height="100"
          />
          <v-icon v-else color="secondary">photo_camera</v-icon>
        </v-avatar>
      </v-col>

      <h2 class="primary--text">{{ profileInfo.fullName }}</h2>
      <label v-if="norwegianBirthdate">{{ norwegianBirthdate }}</label>

      <v-col class="pt-7">
        <v-btn
          to="/personopplysninger"
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          >Personopplysninger
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          to="/helseopplysninger"
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          >Helseopplysninger
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          to="/slett-bruker"
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          >Slett bruker
        </v-btn>
      </v-col>
      <!-- <label class="pt-7">ID: 1234567890</label> -->
      <div class="serial-number-container">
        <p>Legg til serienummer manuelt:</p>
        <div class="manual-serial-wrapper">
          <input
            type="text"
            class="serial-input"
            v-model="customSerialNumber"
          />
          <button @click="goToCode" class="serial-submit">Legg til</button>
        </div>
        <label v-if="profileInfo.qrCode.length == 0 || !profileInfo.qrCode"
          >Du eier ingen serienummere.</label
        >
        <label v-if="profileInfo.qrCode.length == 1">Ditt serienummer:</label>
        <label v-if="profileInfo.qrCode.length > 1">Dine serienummere:</label>
        <label
          class="pt-7"
          v-for="(ownedQrCode, index) in profileInfo.qrCode"
          :key="index"
        >
          <router-link :to="'/u/' + ownedQrCode">{{ ownedQrCode }}</router-link>
        </label>
      </div>
      <!--
        Skriv inn serienummer på smykker brukeren eier, og link til dem
      -->
    </v-row>

    <BottomNav />
  </v-container>
</template>

<script>
import BottomNav from "../components/BottomNav.vue";
import firebase from "@/firebase/db";
export default {
  data() {
    return {
      profileInfo: {
        avatar: {},
        fullName: "",
        birthdate: "",
        qrCode: [],
      },
      customSerialNumber: "",
      norwegianBirthdate: "",
    };
  },

  components: {
    BottomNav,
  },
  mounted() {
    this.getPersonalInfo();
    console.log(this.profileInfo);
    var minute = 60 * 1000;
    setTimeout(function () {
      localStorage.removeItem("hideDialogLocalStorage");
      firebase.auth().signOut();
    }, minute * 15);
  },
  methods: {
    getPersonalInfo() {
      var currentUser = firebase.auth().currentUser;
      var docRef = firebase.firestore().collection("user").doc(currentUser.uid);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          this.profileInfo = doc.data();
          this.formatTime();
        } else {
          console.log("Invalid person");
        }
      });
    },
    formatTime() {
      if (this.profileInfo.birthdate) {
        this.norwegianBirthdate =
          this.profileInfo.birthdate.slice(8, 10) +
          "/" +
          this.profileInfo.birthdate.slice(5, 7) +
          "/" +
          this.profileInfo.birthdate.slice(0, 4);
      }
    },
    async goToCode() {
      var qrCodeFb = firebase
        .firestore()
        .collection("qrCodes")
        .doc(this.customSerialNumber);
      qrCodeFb.get().then((qrCodeDoc) => {
        if (qrCodeDoc.exists) {
          this.$router.push("/u/" + this.customSerialNumber);
        } else {
          alert("Smykket eksisterer ikke. Prøv igjen.");
          console.log(this.customSerialNumber);
          console.log(qrCodeDoc);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

img {
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.v-btn {
  width: 80%;
}
.btn {
  background: $secondary;
}
.v-btn::before {
  background: $secondary;
}

.serial-number-container {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  * {
    padding-top: 5px;
  }
}

.manual-serial-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.serial-input {
  width: 150px;
  border: 1px solid black;
}

.serial-submit {
  padding: 0 15px;
  background-color: $primary;
  color: $secondary;
}
</style>
