<template>
  <div class="modal-backdrop">
    <EmailDialog
      :email="email"
      v-if="dialogVisible"
      @closeEmailPrompt="initRecaptcha()"
    />
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          <h2>SMS Verifikasjon</h2>
          <button type="button" class="close-btn" @click="close">X</button>
        </slot>
      </header>
      <div id="recaptcha-container"></div>
      <section class="modal-body">
        <slot name="body">
          <div v-if="errorMessage">
            <p
              v-for="(msg, index) in errorMessage"
              :key="index"
              class="error--text"
            >
              {{ msg }}
            </p>
          </div>
          <p v-else class="primary--text">Skriv inn SMS koden</p>
          <input
            type="number"
            class="code-input"
            v-model="otp"
            placeholder="Kode"
          />
          <button @click="checkVerification()" class="code-verification-button">
            Verifiser
          </button>
        </slot>
      </section>
      <footer class="modal-footer">
        <slot name="footer">
          <button @click="sendOtp()">Ny SMS kode</button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase/db";
import EmailDialog from "@/components/EmailDialog.vue";

export default {
  components: {
    EmailDialog,
  },
  data() {
    return {
      otp: "",
      errorMessage: "",
      recaptchaVerifier: null,
      dialogVisible: true,
      phoneAuthProvider: null,
      verificationId: null,
    };
  },
  props: {
    email: String,
    password: String,
    telephone: String,
    country: String,
  },
  async mounted() {},
  methods: {
    close() {
      this.$emit("closeModal");
    },

    async initRecaptcha() {
      try {
        var recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function () {},
          }
        );
        this.recaptchaVerifier = recaptchaVerifier;
        await this.sendOtp();
      } catch (error) {
        console.log(error);
        this.recaptchaVerifier = recaptchaVerifier;
        var widgetId = await recaptchaVerifier.render();
        recaptchaVerifier.reset(widgetId);
        await this.initRecaptcha();
      }
    },

    async sendOtp() {
      var currentUser = firebase.auth().currentUser;
      var phoneNumber;
      if (this.country) {
        phoneNumber = this.country + this.telephone;
      } else {
        phoneNumber = "+47" + this.telephone;
      }
      var recaptchaVerifier = this.recaptchaVerifier;
      var multiFactorSession = await currentUser.multiFactor.getSession();
      var phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };
      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      try {
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
      } catch (error) {
        if (error.code == "auth/unverified-email") {
          this.errorMessage = [
            "Vennligst verifiser e-mailen vi sendte til:",
            this.email,
          ];
        }
      }
    },

    async checkVerification() {
      try {
        var fbAuth = firebase.auth;
        var currentUser = firebase.auth().currentUser;
        var verificationId = this.verificationId;
        var verificationCode = this.otp;
        // Ask user for the verification code.
        var cred = await fbAuth.PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        var multiFactorAssertion =
          await fbAuth.PhoneMultiFactorGenerator.assertion(cred);
        // Complete enrollment.
        await currentUser.multiFactor.enroll(
          multiFactorAssertion,
          "Ditt telefon nummer"
        );
        await this.goToProfile();
      } catch (error) {
        console.log(error);
      }
    },

    async goToProfile() {
      var currentUser = await firebase.auth().currentUser;
      console.log(currentUser);
      console.log(currentUser.multiFactors);
      console.log(currentUser.multiFactors[0].factorId);
      if (currentUser.multiFactor.enrolledFactors[0].factorId == "phone") {
        this.$router.push({ name: "MyProfile" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  color: $primary;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid $surface;
  justify-content: space-between;
}
.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-footer {
  border-top: 1px solid $surface;
  flex-direction: column;
  justify-content: flex-end;
}

.code-input {
  width: 50%;
  border: 1px solid $primary;
}

.code-verification-button {
  width: 35%;
  float: right;
  border: 1px solid $primary;
  background-color: $primary;
  color: white;
  font-weight: bold;
}

.close-btn {
  float: right;
  width: 30px;
  background: $primary;
  border: 1px solid $primary;
  border-radius: 2px;
  color: white;
  font-weight: bold;
}
</style>