<template>
  <div>
      <p class="action-block">
  <span class="margin-left--tiny margin-right--large">
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <g stroke="#000" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
        <path
          d="M8.25 18.75v-9a1.5 1.5 0 011.5-1.5h12a1.5 1.5 0 011.5 1.5v10.5a1.5 1.5 0 01-1.5 1.5h-.878M8.25 18.878V9.75H4.921a1.5 1.5 0 00-1.455 1.136L3 13.5l-1.582 1.055A1.502 1.502 0 00.75 15.8v4.45a1.5 1.5 0 001.5 1.5h3.128" />
        <path
          d="M7.5 18.75a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zM18.75 18.75a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zM9.622 21.75h7.006M16.5 7.5a2.25 2.25 0 10-4.5 0v.75h4.5V7.5zM15.75 12v4.5M13.5 14.25H18M14.25 2.25V.75M18 3.75l.75-1.5M10.5 3.75l-.75-1.5" />
      </g>
    </svg>
  </span>
  <span><a href="tel:113">113</a> øyeblikkelig hjelp</span>
</p>
<p class="emergency-doctor__header-block">
  <span class="margin-left--tiny margin-right--large">
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <g stroke="#000" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
        <path
          d="M10 .75A2.625 2.625 0 1110 6a2.625 2.625 0 010-5.25zM10 17.25v4.5M7 17.25v4.5a1.5 1.5 0 003 0 1.5 1.5 0 003 0v-9.5A5.957 5.957 0 0018.032 15h.218a1.5 1.5 0 000-3h-.218a3 3 0 01-2.684-1.659l-.177-.354a4.503 4.503 0 00-2.31-2.148M7.049 7.739A4.498 4.498 0 004 12v.75a1.5 1.5 0 001.5 1.5H7M10 7.5l-3 6.75" />
        <path d="M8.333 11.25h.917a1.5 1.5 0 010 3H7" />
      </g>
    </svg>
  </span>
  <span><a href="tel:116117">116117</a> alvorlig skade</span>
</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>