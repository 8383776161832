<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Gratulerer med din nye CareTag
        </v-card-title>

        <v-card-text v-if="name"
          >Dette smykke er ikke registrert til en bruker. Har du lyst til å
          knytte deg til det?</v-card-text
        >
        <v-card-text v-else
          >Du må logge inn eller lage bruker før du kan binde deg til smykke.
          Vennligst skann koden igjen etter du har lagd bruker</v-card-text
        >
        <v-card-text
          >{{ description }}
          <span id="current-logged-in-user-name">{{ name }}</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="name">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="receiveOwnership"
            >Tilknytt smykke</v-btn
          >
          <v-btn color="primary" text @click="$router.push({ name: 'Home' })"
            >Hovedside</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$router.push({ name: 'Login' })"
            >Logg inn</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="$router.push({ name: 'CreateUser' })"
            >Lag bruker</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "@/firebase/db";
export default {
  data() {
    return {
      dialog: true,
      user: "",
      description: "",
      name: "",
      currentQrCode: [],
    };
  },
  props: {
    header: String,
    message: String,
  },
  mounted() {
    var currentUser = firebase.auth().currentUser;
    console.log(currentUser);
    if (currentUser != null) {
      this.description = "Du er innlogget som: ";
      this.name = currentUser.displayName;
    } else {
      this.description = "Du er ikke innlogget";
    }
  },
  methods: {
    receiveOwnership() {
      var currentUser = firebase.auth().currentUser;
      this.dialog = false;
      if (currentUser == null) {
        this.$router.push({ name: "Home" });
      } else {
        console.log("Du har nå registrert dette smykket under", currentUser);
        this.getQrCodeInfo(currentUser);
      }
    },
    async getQrCodeInfo(currentUser) {
      var code = this.$router.currentRoute.fullPath.slice(3);
      console.log(currentUser);
      console.log(code);
      // Kode
      await this.getAndSetUser(code, currentUser);
      await this.getAndSetQrCode(code, currentUser);
    },
    async getAndSetQrCode(code, currentUser) {
      var qrCodeFb = firebase.firestore().collection("qrCodes").doc(code);
      qrCodeFb.get().then((qrCodeDoc) => {
        if (qrCodeDoc.exists) {
          console.log(qrCodeDoc);
          if (qrCodeDoc.owner) {
            console.log("Smykket har allerede en eier");
          } else {
            console.log(qrCodeDoc);
            qrCodeFb.set({
              owner: currentUser.uid,
              url: "ildacare-firebase.web.app/u/" + qrCodeDoc.id,
            });
            console.log("Eier satt til: " + currentUser.uid);
          }
        } else {
          console.log("Smykket er ikke registrert");
        }
      });
    },
    async getAndSetUser(code, currentUser) {
      this.currentQrCode = code;
      var nameFb = firebase.firestore().collection("user").doc(currentUser.uid);
      nameFb.update({
        qrCode: firebase.firestore.FieldValue.arrayUnion(this.currentQrCode),
      }),
        { merge: true };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
#current-logged-in-user-name {
  color: $primary;
  font-weight: bold;
}

.headline {
  word-break: keep-all;
  text-align: center;
}

</style>