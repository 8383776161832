<template>
  <div class="title-with-content">
    <p class="bold-text">
      {{ title }}:
      <span class="thin-text">{{ items }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "TitleWithContent",
  props: {
    title: String,
    items: String,
  },
};
</script>
