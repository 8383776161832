<template>
  <v-dialog
    fullscreen
    hide-overlay
    v-model="dialog"
    transition="dialog-bottom-transition"
  >
    <div class="v-card-wrapper">
      <v-card class="v-card">
        <v-card-title class="headline primary background--text centered-text">
          Personvernerklæring
        </v-card-title>
        <v-card-text class="primary--text">
          <h2>Personvernerklæring The CareTag</h2>
          <p>Oppdatert september 2023</p>
        </v-card-text>
        <v-card-text
          class="primary--text"
          v-for="(policy, index) in privacyPolicy"
          :key="index"
        >
          <h3>{{ index + 1 }} {{ policy.title }}</h3>
          <p>
            {{ policy.text }}
            <a target="_blank" :href="policy.link" v-if="policy.link"
              >{{ policy.link }}.</a
            ><a
              target="_blank"
              :href="'mailto:' + policy.mail"
              v-if="policy.mail"
              >{{ policy.mail }}</a
            >
          </p>
          <ul v-if="policy.listItems">
            <li v-for="(item, i) in policy.listItems" :key="i">{{ item }}</li>
          </ul>
        </v-card-text>

        <v-card-text class="primary--text">
          <h3>Dine rettigheter</h3>
          <p>
            Du har etter personopplysningsregelverket rett til innsyn, retting
            og sletting av dine opplysninger lagret hos oss. Det har også rett
            til å protestere mot vår behandling og du har rett til å kreve
            dataportabilitet. Du kan håndheve dine rettigheter ved å kontakte
            oss på
            <a href="mailto:post@thecaretag.no" target="_blank"
              >post@thecaretag.no</a
            >. Du kan også kontakte oss på denne adressen ved spørsmål om vår
            behandling av personopplysninger. Dersom du mener at vi behandler
            personopplysninger i strid med regelverket eller informasjonen vi
            har gitt deg, har du alternativt adgang til å kontakte Datatilsynet,
            se
            <a href="https://www.datatilsynet.no/" target="_blank"
              >https://www.datatilsynet.no/</a
            >
            for deres kontaktinformasjon.
          </p>
        </v-card-text>
        <v-card-text class="primary--text">
          <h3>Oppdateringer</h3>
          <p>
            Vi vil oppdatere vår personvernerklæring ved behov, eksempelvis når
            endringer i tjenesten tilsier dette eller dersom regler eller
            praksis endres. EN oppdatert versjon av personverklæringen er alltid
            tilgjengelig på våre nettsider
            <a href="https://www.thecaretag.no/" target="_blank"
              >https://www.thecaretag.no/</a
            >
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="removeDialog()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      privacyPolicy: [
        {
          title: "Innledning",
          text: "Ilda Care AS («vi») selger smykker med påtrykt QR-kode i vår CareTag-serie. Våre CareTag-smykker med tilhørende webapp gir deg mulighet til å registrere opplysninger om deg selv og eventuelt også pårørende slik at viktige helseopplysninger kan mottas og formidles videre av andre i en krisesituasjon. Hensikten med The CareTag er å gi tilgang til potensielt livsviktig informasjon når du ikke kan gjøre rede for deg selv. For at vi skal være i stand til å levere deg denne tjenesten må vi behandle personopplysninger. I vår personvernerklæring finner du nærmere informasjon om vår behandling av personopplysninger og om dine rettigheter. Behandlingsansvarlig er Ilda Care AS, org. no. 923 709 037, postadresse c/o Camilla Johansen Victor, Storgata 21b, 3181 Horten. Vi er underlagt norsk lov og GDPR.",
          link: "",
          mail: "post@thecaretag.no",
          listItems: [],
        },
        {
          title:
            "Behandling av personopplysninger i din brukerprofil og kundeforhold",
          text: "For å bruke våre tjenester må du opprette en profil. Vi vil behandle navn, epostadresse/brukernavn og passord. Disse opplysningene samles inn fra deg og det er frivillig å oppgi dem, men det vil ikke være mulig å opprette konto hos oss uten opplysningene. Vårt formål for behandling er å administrere brukerkontoer og rettslig grunnlag er for å oppfylle avtale med deg. Vi vil behandle opplysningene så lenge du har en konto hos oss. Du kan selv slette din konto når du måtte ønske det. i vil også behandle din epostadresse og bruke denne til å sende deg oppdatert informasjon om tjenesten, herunder dersom vi endrer vilkår av betydning.",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Behandling av personopplysninger i CareTag-app",
          text: "I vår webapp har du muligheten til frivillig å registrere opplysninger om deg selv og om pårørende. Navn, fødselsdato og ditt profilbilde er obligatorisk informasjon og som må oppgi for å ta tjenesten i bruk slik at andre skal kunne identifisere deg i en krisesituasjon. Du har også muligheten til å oppgi opplysninger som blodtype, allergier, diagnoser, pårørende og standpunkt til organdonasjon hvis du ønsker. Disse opplysningene samles inn fra deg. Vårt formål er at opplysningene du har oppgitt skal være tilgjengelig for andre ved andres skanning av QR-koden i krisesituasjoner. Rettslig grunnlag for vår behandling av Navn, fødselsdato, profilbilde og standpunkt til organdonasjon er for å oppfylle en avtale med deg. Rettslig grunnlag for behandling av opplysninger om pårørende er interesseavveining, og vår interesse er at andre skal kunne kontakte dine pårørende i en krisesituasjon. Opplysninger om blodtype, allergier og diagnoser er såkalte spesielle kategorier. Vårt grunnlag for å behandle disse er ditt samtykke. Vi ber deg gi samtykke i vår løsning for å oppgi eller redigere dine opplysninger. Du har anledning til å trekke tilbake ditt samtykke. Vi vil behandle opplysningene du har oppgitt så lenge du har en aktiv konto hos oss. Du kan når som helst avslutte din konto hos oss. Ved sletting av konto vil alle opplysninger om deg slettes hos oss. Du kan når som helst endre informasjonen du har lagt inn i vår tjeneste.",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Informasjon til pårørende om vår tjeneste",
          text: "Vår tjeneste gir deg mulighet til å lagre informasjon om dine pårørende. Det er god skikk å informere dine pårørende dersom du benytte denne muligheten.",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Utlevering av personopplysninger",
          text: "Vår tjeneste er laget for at andre i en krisesituasjon skal få tilgang til informasjon du har oppgitt. Din informasjon er leselig, og er ment å være leselig, for den som skanner QR-koden i ditt CareTag-smykke. Ut over dette leverer vi ikke ut personopplysninger til andre med mindre vi etter norsk lov er pålagt å gjøre det.",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Vår bruk av leverandører og overføring til utland",
          text: "Vi bruker underleverandøren Google for å få vår tjeneste til å virke. Google vil primært behandle personopplysninger på vegne av oss i Europa. Google er et amerikansk foretak hvilket betyr at Google i USA i visse situasjoner har tilgang til personopplysninger i vår tjeneste.",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Sikkerhet",
          text: "Vi har innført flere tiltak for å sikre vår tjeneste mot at uvedkommende kan endre dine opplysninger eller lese dine opplysninger. Ett av disse tiltakene er tofaktorautentisering for din pålogging. Vi minner igjen om at formålet med vår tjeneste er at andre i krisesituasjoner kan lese informasjon du har oppgitt ved å lese av QR-koden i ditt smykke. Pass derfor godt på ditt smykke. Vi står ikke ansvarlig for riktigheten av informasjon du selv legger inn i helse opplysninger, du står selv ansvarlig for å legge inn riktige helseopplysninger",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Lovvalg og verneting",
          text: "Disse brukervilkårene er underlagt norsk lov. Eventuelle tvister i forbindelse med vilkårene skal avgjøres av norske domstoler med mindre tvingende forbrukervernregler leder til noe annet. ",
          link: "",
          mail: "",
          listItems: [],
        },
        {
          title: "Konfliktløsning",
          text: "Klager rettes til selger innen rimelig tid, jf. punkt 9 og 10. Partene skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke lykkes, kan kjøperen ta kontakt med Forbrukerrådet for mekling. Forbrukerrådet er tilgjengelig  på  telefon 23  400  500  eller ",
          link: "www.forbrukerradet.no",
          mail: "",
          listItems: [],
        },
      ],
    };
  },
  methods: {
    removeDialog() {
      this.$emit("closePrivacyPolicy");
    },
  },
};
</script>

<style scoped>
.v-card-wrapper {
  height: fit-content;
}

.v-card {
  height: 100%;
}

.centered-text {
  justify-content: center;
}

.primary--text {
  padding: 15px 30%;
}

.v-btn {
  width: 100%;
}
</style>
