<template>
  <div @click="logout()">
    <v-bottom-navigation
      app
      flat
      :background-color="color"
      class="background--text cursor"
      height="80px"
    >
      <v-toolbar-title class="ma-auto subtitle-1 font-weight-bold"
        >Logg ut</v-toolbar-title
      >
    </v-bottom-navigation>
  </div>
</template>

<script>
import firebase from "../firebase/db";
export default {
  computed: {
    color() {
      return "primary";
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("hideDialogLocalStorage");
      firebase.auth().signOut();
    },
  },
};
</script>

<style>
</style>