<template>
  <div class="landing-page">
    <div v-if="showUserInfo">
      <v-row class="text-center flex-column">
        <v-col>
          <v-avatar
            class="text-center image-container"
            color="primary"
            size="120"
          >
            <img
              v-if="info.avatar"
              :src="info.avatar"
              width="100"
              height="100"
            />
            <v-icon v-else color="secondary">photo_camera</v-icon>
          </v-avatar>
        </v-col>
        <v-col>
          <p class="name-para">{{ info.fullName }}</p>
          <p class="bold-text" v-if="info.birthdate">
            Fødselsdato:
            {{
              info.birthdate.slice(8, 10) +
              "/" +
              info.birthdate.slice(5, 7) +
              "/" +
              info.birthdate.slice(0, 4)
            }}
          </p>
          <p class="bold-text" v-else>Fødselsdato: Ikke oppgitt</p>
        </v-col>
        <CallForHelp />
        <v-col>
          <div class="surface-background" v-if="info.healthInformation">
            <p class="pad-top name-para">Mine helseopplysninger</p>
            <TitleWithContent
              v-if="info.healthInformation.bloodtype"
              title="Blodtype"
              :items="info.healthInformation.bloodtype"
            />
            <TitleWithContent v-else title="Blodtype" items="Ikke oppgitt" />
            <TitleWithContent
              v-if="info.healthInformation.organdonor"
              title="Organdonor"
              :items="info.healthInformation.organdonor"
            />
            <TitleWithContent v-else title="Organdonør" items="Ikke oppgitt" />
            <TitleWithContent
              v-if="info.healthInformation.diabetes"
              title="Diabetes"
              :items="info.healthInformation.diabetes"
            />
            <TitleWithContent v-else title="Diabetes" items="Ikke oppgitt" />
            <TitleWithContent
              v-if="info.healthInformation.epilepsy"
              title="Epilepsi"
              :items="info.healthInformation.epilepsy"
            />
            <TitleWithContent v-else title="Epilepsi" items="Ikke oppgitt" />
            <TitleWithContent
              v-if="info.healthInformation.pacemaker"
              title="Pacemaker"
              :items="info.healthInformation.pacemaker"
            />
            <TitleWithContent v-else title="Pacemaker" items="Ikke oppgitt" />
            <DividerComponent />
            <TitleWithContent
              v-if="info.healthInformation.diagnoses"
              title="Relevant informasjon"
              :items="info.healthInformation.diagnoses"
            />
            <TitleWithContent
              v-else
              title="Relevant informasjon"
              items="Ikke oppgitt"
            />
            <DividerComponent />
          </div>
          <div v-else>Ingen helseopplysninger er lagt til.</div>
        </v-col>
      </v-row>
      <div @click="$router.push({ name: 'Home' })">
        <v-bottom-navigation
          app
          flat
          class="background--text cursor primary"
          height="80px"
        >
          <v-toolbar-title class="ma-auto subtitle-2 white--text"
            >THE CARETAG</v-toolbar-title
          >
        </v-bottom-navigation>
      </div>
    </div>
    <div v-if="showUnusedJewelry">
      <MessageDialog />
    </div>
  </div>
</template>

<script>
import DividerComponent from "../components/DividerComponent.vue";
import TitleWithContent from "../components/TitleWithContent.vue";
import MessageDialog from "@/components/MessageDialog.vue";
import firebase from "@/firebase/db";
import CallForHelp from "../components/CallForHelp.vue";
export default {
  name: "LandingPage",
  data() {
    return {
      user: "",
      info: {},
      showUserInfo: false,
      showUnusedJewelry: false,
    };
  },
  components: {
    DividerComponent,
    TitleWithContent,
    MessageDialog,
    CallForHelp,
  },
  async created() {
    await this.getOwnerOfQrCode();
  },
  mounted() {
    var hour = 60 * 60 * 1000;
    setTimeout(function () {
      localStorage.removeItem("hideDialogLocalStorage");
      firebase.auth().signOut();
    }, hour);
  },
  methods: {
    async getOwnerOfQrCode() {
      var path = this.$router.currentRoute.fullPath.slice(3);
      var docRef = await firebase.firestore().collection("qrCodes").doc(path);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          this.user = doc.data().owner;
          if (this.user != "" && this.user != undefined) {
            var userRef = firebase
              .firestore()
              .collection("user")
              .doc(this.user);
            userRef.onSnapshot((user) => {
              if (user.exists) {
                this.showUnusedJewelry = false;
                this.showUserInfo = true;
                this.info = user.data();
              } else {
                this.showUserInfo = false;
              }
            });
          } else {
            this.showUnusedJewelry = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

img {
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.image-container {
  margin-top: 0.5em;
}

.pad-top {
  padding-top: 0.5em;
}

.surface-background {
  background-color: $surface;
  // min-height: 0; // For å ikke få noe hvitt mellom helseopplysninger og footer'n
}

.name-para {
  font-size: 1em;
  font-weight: bold;
}
</style>