<template>
  <v-container>
    <TopNav />
    <v-row class="flex-column pa-8">
      <h1 class="pb-7">Glemt passord</h1>
      <form @submit.prevent>
        <div>
          <label for="email" class="font-bold text-gray-700">E-mail</label>
          <v-text-field
            type="email"
            v-model="email"
            id="email"
            class="mt-2 pa-0"
            required
          />
        </div>
        <p id="error-handler" class="error--text"></p>
        <div class="pt-4">
          <v-btn
            type="submit"
            @click="sendEmail"
            depressed
            rounded
            outlined
            color="primary"
            class="btn pa-7 primary--text"
            >Send
          </v-btn>
        </div>
      </form>
    </v-row>
  </v-container>
</template>

<script>
import TopNav from "../components/TopNav.vue";
import firebase from "@/firebase/db";
export default {
  components: {
    TopNav,
  },
  data() {
    return {
      email: "",
      error: null,
      emailSending: false,
    };
  },
  methods: {
    sendEmail() {
      var errorHandler = document.getElementById("error-handler");
      if (!this.email) {
        this.error = "Please type in a valid email address.";
        return;
      }
      this.error = null;
      this.emailSending = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.emailSending = false;
            errorHandler.innerHTML = "Email sendt til: " + this.email + ". Sjekk søppelpost hvis du ikke finner den.";
        })
        .catch((error) => {
          console.log(error);
          this.emailSending = false;
          this.error = error.message;
          console.log(this.error);
          if (error.code == "auth/invalid-email") {
            errorHandler.innerHTML = "Ugyldig email";
          } else if (error.code == "auth/user-not-found") {
            errorHandler.innerHTML = "Email er ikke registrert";
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
.v-btn {
  width: 100%;
}
.btn {
  background: $secondary;
}
.v-btn::before {
  background: $secondary;
}
</style>