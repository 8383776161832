<template>
  <div>
    <h1>Registrering bekreftet</h1>
    <p>
      Nå kan du logge deg inn ved å klikke
      <router-link :to="{ name: 'login' }">her</router-link>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>