import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBIzjJiqiBKT3F6IyUOjqZ8FS5YWiPAJWs",
  authDomain: "ildacare-firebase.firebaseapp.com",
  projectId: "ildacare-firebase",
  storageBucket: "ildacare-firebase.appspot.com",
  messagingSenderId: "783481322297",
  appId: "1:783481322297:web:5f92815d1308467627c6e8",
  measurementId: "G-953PVRNF6X"
};

firebase.initializeApp(firebaseConfig);

export default firebase;