<template>
  <v-container>
    <TopNav />
    <SigninWithPhoneComponent
      v-if="loginModalOpen"
      @closeModal="closeModal"
      :email="email.trim(' ')"
      :password="password"
    />
    <SignupWithPhoneComponent
      v-if="accountCreationInitiated"
      @closeModal="closeModal"
      :email="email.trim(' ')"
      :password="password"
      :telephone="telephone"
    />
    <v-row class="flex-column pa-8">
      <h1 class="pb-7">Logg inn</h1>
      <label>E-mail</label>
      <v-text-field
        v-model="email"
        class="mt-2 pa-0"
        :rules="[rules.required, rules.email]"
      >
      </v-text-field>
      <label>Passord</label>
      <div class="password-with-show-button">
        <v-text-field
          v-if="showPassword"
          v-model="password"
          type="text"
          class="mt-2 pa-0"
          :rules="[rules.required]"
        >
          :append-icon="value ? 'visibility' : 'visibility_off'"
          @click:append="() => (value = !value)" :type="value ? 'password' :
          'text'" >
        </v-text-field>
        <v-text-field
          v-else
          v-model="password"
          type="password"
          class="mt-2 pa-0"
          :rules="[rules.required]"
        >
          :append-icon="value ? 'visibility' : 'visibility_off'"
          @click:append="() => (value = !value)" :type="value ? 'password' :
          'text'" >
        </v-text-field>
        <div class="show-password-button" @click="showPassword = !showPassword">
          &#128065;
        </div>
      </div>
      <p id="error-handler" class="error--text"></p>
      <v-col>
        <v-btn
          @click="login"
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          >Logg inn</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          to="/glemt-password"
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          >Glemt passord</v-btn
        >
      </v-col>
    </v-row>
    <v-bottom-navigation
      app
      flat
      :background-color="color"
      class="background--text"
      height="80px"
    >
      <v-toolbar-title class="ma-auto subtitle-2">THE CARETAG</v-toolbar-title>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import firebase from "@/firebase/db";
import TopNav from "@/components/TopNav.vue";
import SigninWithPhoneComponent from "@/components/SigninWithPhoneComponent.vue";
import SignupWithPhoneComponent from "@/components/SignupWithPhoneComponent.vue";

export default {
  components: {
    TopNav,
    SigninWithPhoneComponent,
    SignupWithPhoneComponent,
  },
  data() {
    return {
      value: String,
      email: "",
      password: "",
      telephone: "",
      showPassword: false,
      loginModalOpen: false,
      accountCreationInitiated: false,
      rules: {
        required: (value) => !!value || "Må fylles ut.",
        // email: (value) => {
        //   const pattern =
        //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //   return pattern.test(value) || "Ugyldig e-mail.";
        // },
      },
    };
  },
  computed: {
    color() {
      return "primary";
    },
  },
  methods: {
    async login() {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(this.email.trim(" "), this.password)
            .then((result) => {
              var user = result.user;
              var docRef = firebase
                .firestore()
                .collection("user")
                .doc(user.uid);
              docRef.get().then((doc) => {
                if (doc.exists) {
                  this.telephone = doc.data().phoneNumber;
                } else {
                  console.log("Ingen data");
                }
                this.accountCreationInitiated = true;
              });
            })
            .catch((error) => {
              var errorHandler = document.getElementById("error-handler");
              console.log(error);
              if (error.code == "auth/multi-factor-auth-required") {
                this.loginModalOpen = true;
              } else if (
                error.code == "auth/wrong-password" ||
                error.code == "auth/user-not-found"
              ) {
                errorHandler.innerHTML = "Feil email eller passord.";
              } else if (error.code == "auth/too-many-requests") {
                errorHandler.innerHTML =
                  "For mange forespørsler. Gå til forsiden, og prøv igjen om noen minutter.";
              }
              // else if (error.code == "") {

              // }
            });
        });
    },
    closeModal() {
      this.loginModalOpen = false;
      this.accountCreationInitiated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
.v-btn {
  width: 100%;
}
.btn {
  background: $secondary;
}
.v-btn::before {
  background: $secondary;
}

.password-with-show-button {
  display: flex;
  flex-direction: row;
}
.show-password-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  height: 1em;
  font-size: 2.5em;
  border: 1px solid $primary;
  border-radius: 25%;
}
</style>
