<template>
  <v-row v-if="!displayDialog || displayDialog == false" justify="center">
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Personvern </v-card-title>

        <v-card-text>
          Vær oppmerksom på at de opplysningene du lagrer blir tilgjengelig for
          alle som skanner din CareTag.
        </v-card-text>
        <v-card-text>
          <v-checkbox id="vCheckbox" v-model="checkbox" class="pb-4">
            <template v-slot:label>
              <label class="l-font-size">
                Ok, ikke vis igjen denne økten.
              </label>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="closeModal"> Avslå </v-btn>

          <v-btn color="green darken-1" text @click="hideDialog()">
            Godta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      checkbox: false,
      hideMessage: false,
      displayDialog: false,
    };
  },
  props: {
    saveInfo: null,
  },
  mounted() {
    this.displayDialog = JSON.parse(localStorage.getItem('hideDialogLocalStorage'));
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.$emit("closeModalFromChild");
    },
    async hideDialog() {
      localStorage.setItem("hideDialogLocalStorage", this.checkbox);
      this.saveInfo();
    },
  },
};
</script>

<style scoped>
.l-font-size {
  font-size: 0.8rem;
}
</style>