<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          <h2>SMS Verifikasjon</h2>
          <button type="button" class="close-btn" @click="close">X</button>
        </slot>
      </header>
      <div id="recaptcha-container"></div>
      <section class="modal-body">
        <slot name="body">
          <p id="modal-info-paragraph"></p>
          <p>Skriv inn SMS koden</p>
          <input
            type="number"
            class="code-input"
            v-model="otp"
            placeholder="Kode"
          />
          <button
            @click="loginWithMultiFactor()"
            class="code-verification-button"
          >
            Verifiser
          </button>
        </slot>
      </section>
      <footer class="modal-footer">
        <slot name="footer">
          <button @click="sendOtp()">Send SMS på nytt</button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase/db";
export default {
  data() {
    return {
      phNo: "",
      otp: "",
      userInfo: null,
      recaptchaVerifier: null,
      verificationId: null,
      resolver: null,
    };
  },
  props: {
    email: String,
    password: String,
  },
  async mounted() {
    this.initRecaptcha();
    await this.getUser();
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },

    async initRecaptcha() {
      try {
        var recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function() {},
          }
        );
        this.recaptchaVerifier = recaptchaVerifier;
      } catch (error) {
        console.log(error);
        this.recaptchaVerifier = recaptchaVerifier;
        var widgetId = await recaptchaVerifier.render();
        recaptchaVerifier.reset(widgetId);
        setTimeout(() => {
          this.initRecaptcha();
        }, 1000);
      }
    },

    async getUser() {
      var resolver;
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        console.log("Noe gikk feil");
      } catch (error) {
        if (error.code == "auth/multi-factor-auth-required") {
          resolver = error.resolver;
          // Ask user which second factor to use.
          if (resolver.hints[0].factorId === "phone") {
            this.phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            };
            this.resolver = resolver;
            this.sendOtp();
          } else {
            console.log("Feil i telefon oppsettet.");
          }
        } else if (error.code == "auth/invalid-verification-code") {
          document.getElementsByClassName("modal-info-paragraph").innerHTML =
            "Feil kode";
        } else if (error.code == "auth/wrong-password") {
          document.getElementsByClassName("modal-info-paragraph").innerHTML =
            "Feil passord";
        }
      }
    },

    async sendOtp() {
      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        this.phoneInfoOptions,
        this.recaptchaVerifier
      );
    },

    async loginWithMultiFactor() {
      var fbAuth = firebase.auth;
      var resolver = this.resolver;
      var verificationId = this.verificationId;
      var verificationCode = this.otp;
      var cred = await fbAuth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      var multiFactorAssertion = await fbAuth.PhoneMultiFactorGenerator.assertion(
        cred
      );
      await resolver.resolveSignIn(multiFactorAssertion);
        await this.goToProfile();
    },
    async goToProfile() {
      var currentUser = await firebase.auth().currentUser;
      if (currentUser.multiFactor.enrolledFactors[0].factorId == "phone") {
        this.$router.push({ name: "MyProfile" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  color: $primary;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid $surface;
  justify-content: space-between;
}
.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-footer {
  border-top: 1px solid $surface;
  flex-direction: column;
  justify-content: flex-end;
}

.code-input {
  width: 50%;
  border: 1px solid $primary;
}

.code-verification-button {
  width: 35%;
  float: right;
  border: 1px solid $primary;
  background-color: $primary;
  color: white;
  font-weight: bold;
}

#modal-info-paragraph {
  color: salmon;
}

.close-btn {
  float: right;
  width: 30px;
  background: $primary;
  border: 1px solid $primary;
  border-radius: 2px;
  color: white;
  font-weight: bold;
}
</style>
