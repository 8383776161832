<template>
  <v-container>
    <TopNav />
    <SignupWithPhoneComponent
      v-if="accountCreationInitiated"
      @closeModal="closeModal"
      :email="email.trim(' ')"
      :password="password"
      :telephone="telephone"
      :country="selectedCountry"
    />
    <v-row class="flex-column pa-8">
      <h1 class="pb-7">Lag bruker</h1>

      <label>Fullt navn*</label>
      <v-text-field v-model="name" :rules="[rules.required]" class="mt-2 pa-0">
      </v-text-field>

      <label>E-mail*</label>
      <v-text-field v-model="email" :rules="[rules.required]" class="mt-2 pa-0">
      </v-text-field>

      <label>Fødselsdato*</label>
      <div class="mt-2 pa-0 fancy-underline">
        <input
          type="date"
          class="mt-2 pa-0"
          v-model="birthdate"
          :rules="[rules.required, rules.birth]"
        />
      </div>

      <label>Mobiltelefon*</label>
      <span>Nummeret blir brukt til tofaktorautentisering</span>
      <div class="horizontal-row">
        <select name="" id="" v-model="selectedCountry">
          <option
            v-for="(country, i) in countryOptions"
            :key="i"
            :value="country.num"
          >
            {{ country.flag }}{{ country.num }}
          </option>
        </select>
        <v-text-field type="number" v-model="telephone" class="mt-2 pa-0">
        </v-text-field>
      </div>

      <label>Passord*</label>
      <v-text-field
        v-model="password"
        :append-icon="value ? 'visibility' : 'visibility_off'"
        @click:append="() => (value = !value)"
        :type="value ? 'password' : 'text'"
        :rules="[rules.required, rules.min]"
        class="mt-2 pa-0"
        hint="Minst 8 tegn"
        counter
      >
      </v-text-field>

      <label>Gjenta passord*</label>
      <v-text-field
        v-model="rePassword"
        :append-icon="value ? 'visibility' : 'visibility_off'"
        @click:append="() => (value = !value)"
        :rules="[rules.required, rules.min, passwordConfirmationRule]"
        :type="value ? 'password' : 'text'"
        hint="Minst 8 tegn"
        counter
        class="mt-2 pa-0"
      >
      </v-text-field>

      <p id="error-handler" class="error--text"></p>

      <v-col class="pa-0">
        <v-checkbox v-model="checkbox" class="pb-4">
          <template v-slot:label>
            <label>
              Jeg godkjenner at informasjonen om meg brukes i henhold til
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a @click="ToSDialog = true" v-on="on">
                    personvernvilkårene.
                  </a>
                  <ToS v-if="ToSDialog" @closeToS="ToSDialog = false" />
                </template>
                Opens in new window
              </v-tooltip>
            </label>
          </template>
        </v-checkbox>
        <!-- <button @click="logTest">log test</button> -->
        <v-btn
          depressed
          rounded
          outlined
          class="btn pa-7 primary--text"
          @click="submit"
          >Opprett bruker</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TopNav from "../components/TopNav.vue";
import SignupWithPhoneComponent from "@/components/SignupWithPhoneComponent.vue";
import firebase from "@/firebase/db";
import ToS from "../components/ToS.vue";

export default {
  name: "CreateUser",
  components: {
    TopNav,
    SignupWithPhoneComponent,
    ToS,
  },

  data() {
    return {
      ToSDialog: false,
      value: String,
      checkbox: false,
      accountCreationInitiated: false,
      name: "",
      email: "",
      birthdate: "",
      telephone: "",
      password: "",
      rePassword: "",
      resolver: "",
      rules: {
        required: (value) => !!value || "Må fylles ut.",
        min: (v) => v.length >= 8 || "Minst 8 tegn.",
        birth: (v) => v.length >= 10 || "Ugyldig fødselsdag.",
        // phone: (v) => v.length >= 8 || "Ugyldig telefon nummer.",
        // email: (value) => {
        //   const pattern =
        //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //   return pattern.test(value) || "Ugyldig e-mail.";
        // },
      },
      selectedCountry: "+47",
      countryOptions: [
        { flag: "DK", num: "+45" },
        { flag: "SE", num: "+46" },
        { flag: "NO", num: "+47" },
        { flag: "FI", num: "+358" },
      ],
    };
  },
  computed: {
    passwordConfirmationRule() {
      return this.password === this.rePassword || "Passordet må stemme overens";
    },
  },
  methods: {
    logTest() {
      console.log(this.selectedCountry);
    },
    closeModal() {
      this.accountCreationInitiated = false;
    },
    submit() {
      console.log(this.birthdate.length);
      var errorHandler = document.getElementById("error-handler");
      // var actionCodeSettings = {
      //   url: "https://ildacare-firebase.web.app/login",
      //   handleCodeInApp: true,
      // };
      if (this.name == "") {
        errorHandler.innerHTML = "Navn kan ikke være tomt";
      } else if (this.email == "") {
        errorHandler.innerHTML = "Email må fylles ut";
      } else if (this.birthdate.length < 10 || this.birthdate.length > 10) {
        errorHandler.innerHTML = "Skriv inn bursdag";
      } else if (this.telephone.length < 8) {
        errorHandler.innerHTML = "Telefonnummer for kort";
      } else if (this.password.length < 8) {
        errorHandler.innerHTML = "Passord for kort";
      } else if (this.password != this.rePassword) {
        errorHandler.innerHTML = "Passordene er ikke like";
      } else if (!this.checkbox) {
        errorHandler.innerHTML = "Du må godta personværnvilkårene";
      } else {
        errorHandler.innerHTML = "";
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email.trim(" "), this.password)
          .then((data) => {
            let currentUser = firebase.auth().currentUser;
            currentUser
              .sendEmailVerification()
              .then(() => {
                // window.localStorage.setItem("emailForSignIn", this.email);
                // ...
                data.user.updateProfile({
                  displayName: this.name.trim(" "),
                  phoneNumber: this.selectedCountry + this.telephone,
                });
                var docRef = firebase
                  .firestore()
                  .collection("user")
                  .doc(currentUser.uid);
                docRef.set({
                  fullName: this.name,
                  phoneNumber: this.selectedCountry + this.telephone,
                  birthdate: this.birthdate,
                });
                this.accountCreationInitiated = true;
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            if (error.code == "auth/email-already-in-use") {
              errorHandler.innerHTML = "Denne e-mailen er allerede i bruk";
            }
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
.v-btn {
  width: 100%;
}
.btn {
  background: $secondary;
}
.fancy-underline {
  margin-bottom: 20px;
  border-bottom: 1px solid #999999;
}
.horizontal-row {
  display: flex;
  flex-direction: row;
}
</style>
