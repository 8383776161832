<template>
  <div class="divider"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
.divider {
    margin: 1.5em;
    // width: 100%;
    height: 1px;
    background-color: $primary;
}

</style>