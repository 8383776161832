<template>
  <v-container>
    <TopNav />
    <v-row class="flex-column pa-8">
      <h1>Slett bruker</h1>
      <p class="pt-7">
        Ved å slette din bruker vil all informasjon i din profil bli permanent
        slettet fra vårt system og vi kan ikke hente frem informasjonen igjen.
        Etter å ha bekreftet at du vil slette din bruker blir du automatisk
        logget ut og du vil ikke kunne logge deg inn.
      </p>
      <h2 class="pt-7">Ønsker du å slette din bruker?</h2>

      <v-col class="text-center pt-7">
        <v-btn
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          @click.stop="dialog = true"
          >Slett bruker
        </v-btn>
        <v-dialog v-model="dialog" max-width="340">
          <v-card>
            <v-card-title class="headline">
              Bekreft sletting av bruker
            </v-card-title>

            <v-card-text>
              Ved å trykke "agree" godtar du at din bruker og all informasjon i
              din profil blir permanent slettet fra vårt system.
            </v-card-text>

            <v-card-actions>
              <v-btn
                class="ma-3 pa-0 card-btn"
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Disagree
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-3 pa-0 card-btn"
                color="green darken-1"
                text
                @click="deleteUser(), (dialog = false), (overlay = !overlay)"
              >
                Agree
              </v-btn>
              <v-overlay :value="overlay">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <BottomNav />
  </v-container>
</template>

<script>
import firebase from "@/firebase/db";
import TopNav from "../components/TopNav.vue";
import BottomNav from "../components/BottomNav.vue";

export default {
  data() {
    return {
      dialog: false,
      overlay: false,
    };
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 1500);
    },
  },
  components: {
    TopNav,
    BottomNav,
  },
  methods: {
    async deleteUser() {
      var currentUser = firebase.auth().currentUser;
      var userData = firebase
        .firestore()
        .collection("user")
        .doc(currentUser.uid);
      userData.onSnapshot((userQrCodes) => {
        var allQrCodes = userQrCodes.data().qrCode;
        if (userQrCodes.exists) {
          for (let ci = 0; ci < allQrCodes.length; ci++) {
            // legg til slettefunskjon
            var qrCodeData = firebase
              .firestore()
              .collection("qrCodes")
              .doc(allQrCodes[ci]);
            qrCodeData.update({
              owner: firebase.firestore.FieldValue.delete(),
            });
          }
        } else {
          console.log("Du har ingen serienummer.");
        }
      });
      await firebase
        .firestore()
        .collection("user")
        .doc(currentUser.uid)
        .delete()
        .then(() => {
          firebase.auth().currentUser.delete();
          console.log("Document successfully deleted!");
          // await firebase.firestore().collection("qrCodes").doc;
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
      localStorage.removeItem("hideDialogLocalStorage");
      await firebase.auth().signOut();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
.card-btn {
  width: 10%;
}
.btn {
  width: 100%;
  background: $secondary;
}
.v-btn::before {
  background: $secondary;
}
</style>
