<template>
  <v-container>
    <TopNav />
    <v-row class="flex-column pa-8">
      <h1 class="pb-7">Helseopplysninger</h1>

      <label>Blodtype</label>
      <v-select
        class="pa-0 bloodtype"
        :items="bloodtype"
        v-model="updateHealthInformation.bloodtype"
      >
      </v-select>
      <v-col class="pa-0 ma-0">
        <label>Organdonor</label>
        <v-select
          class="pa-0 organdonor"
          :items="organdonor"
          v-model="updateHealthInformation.organdonor"
        >
        </v-select>

        <!-- <label>Allergi</label>
        <div
          v-for="(item, index) in updateHealthInformation.autoAllergies"
          :key="index"
        >
          {{ item }}
        </div>
        <v-autocomplete
          multiple
          :items="autoAllergy"
          v-model="updateHealthInformation.autoAllergies"
        >
        </v-autocomplete> -->

        <label>Diabetes</label>
        <v-select
          class="pa-0 bloodtype"
          :items="diabetes"
          v-model="updateHealthInformation.diabetes"
        >
        </v-select>

        <label>Epilepsi</label>
        <v-select
          class="pa-0 organdonor"
          :items="epilepsy"
          v-model="updateHealthInformation.epilepsy"
        >
        </v-select>

        <label>Pacemaker</label>
        <v-select
          class="pa-0 organdonor"
          :items="pacemaker"
          v-model="updateHealthInformation.pacemaker"
        >
        </v-select>

        <v-spacer></v-spacer>

        <label>Diagnoser</label>
        <div>
          <p>{{ updateHealthInformation.diagnoses }}</p>

          <textarea
            @keyup="addDiagnose"
            v-model="diagnose"
            name=""
            class="diagnose-text-area"
          ></textarea>
        </div>
      </v-col>
    </v-row>
    <div @click="checkLocalStorage" class="pointer-cursor">
      <v-bottom-navigation
        app
        flat
        :background-color="color"
        class="secondary--text"
        height="80px"
      >
        <v-toolbar-title class="ma-auto subtitle-1 font-weight-bold cursor"
          >Lagre</v-toolbar-title
        >
      </v-bottom-navigation>
    </div>
    <PopupModal
      :saveInfo="saveHealthInfo"
      v-if="displayModal"
      @closeModalFromChild="displayModal = false"
    />
  </v-container>
</template>

<script>
import firebase from "@/firebase/db";
import TopNav from "../components/TopNav.vue";
import PopupModal from "../components/PopupModal.vue";

export default {
  components: {
    TopNav,
    PopupModal,
  },
  data() {
    return {
      displayModal: false,
      bloodtype: ["A-", "A+", "B-", "B+", "O-", "O+", "AB-", "AB+", "Vet ikke"],
      organdonor: [
        "Ja, jeg ønsker å være organdonor",
        "Nei, jeg ønsker ikke å være organdonor",
      ],
      autoAllergy: ["Støvallergi", "Dyrehårallergi", "Pollenallergi", "Nei"],
      diabetes: ["Diabetes 1", "Diabetes 1 med pumpe", "Diabetes 2", "Nei"],
      epilepsy: ["Jeg har epilepsi", "Nei"],
      pacemaker: ["Jeg har pacemaker", "Nei"],
      diagnose: "",
      selectedAllergy: "",
      updateHealthInformation: {
        bloodtype: this.bloodtype,
        organdonor: this.organdonor,
        diabetes: this.diabetes,
        epilepsy: this.epilepsy,
        pacemaker: this.pacemaker,
        autoAllergies: [],
        diagnoses: this.diagnose,
      },
    };
  },
  watch: {},
  computed: {
    color() {
      return "primary";
    },
  },
  mounted() {
    this.getHealthInformation();
  },
  methods: {
    addDiagnose() {
      // if (
      //   this.diagnose &&
      //   !this.updateHealthInformation.diagnoses.includes(this.diagnose)
      // ) {
      //   this.updateHealthInformation.diagnoses.push(this.diagnose);
      //   this.diagnose = "";
      // }err
      this.updateHealthInformation.diagnoses = this.diagnose;
      console.log(this.updateHealthInformation.diagnoses);

      console.log(this.diagnose);
    },
    getHealthInformation() {
      var currentUser = firebase.auth().currentUser;
      var docRef = firebase.firestore().collection("user").doc(currentUser.uid);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          this.updateHealthInformation.bloodtype =
            doc.data().healthInformation.bloodtype;
          this.updateHealthInformation.organdonor =
            doc.data().healthInformation.organdonor;
          this.updateHealthInformation.autoAllergies =
            doc.data().healthInformation.autoAllergy;
          this.updateHealthInformation.diabetes =
            doc.data().healthInformation.diabetes;
          this.updateHealthInformation.epilepsy =
            doc.data().healthInformation.epilepsy;
          this.updateHealthInformation.pacemaker =
            doc.data().healthInformation.pacemaker;
          this.updateHealthInformation.diagnoses =
            doc.data().healthInformation.diagnoses;
        } else {
          console.log("no information");
        }
      });
      console.log(docRef);
    },

    checkLocalStorage() {
      if (JSON.parse(localStorage.getItem("hideDialogLocalStorage"))) {
        this.saveHealthInfo();
      } else {
        this.displayModal = true;
      }
    },

    saveHealthInfo() {
      var currentUser = firebase.auth().currentUser;
      var docRef = firebase.firestore().collection("user").doc(currentUser.uid);
      docRef
        .set(
          {
            healthInformation: {
              bloodtype: this.updateHealthInformation.bloodtype
                ? this.updateHealthInformation.bloodtype
                : "",
              organdonor: this.updateHealthInformation.organdonor
                ? this.updateHealthInformation.organdonor
                : "",
              diabetes: this.updateHealthInformation.diabetes
                ? this.updateHealthInformation.diabetes
                : "",
              epilepsy: this.updateHealthInformation.epilepsy
                ? this.updateHealthInformation.epilepsy
                : "",
              pacemaker: this.updateHealthInformation.pacemaker
                ? this.updateHealthInformation.pacemaker
                : "",
              autoAllergy: this.updateHealthInformation.autoAllergies
                ? this.updateHealthInformation.autoAllergies
                : "",
              diagnoses: this.updateHealthInformation.diagnoses
                ? this.updateHealthInformation.diagnoses
                : "",
            },
          },
          { merge: true }
        )
        .then(() => {
          console.log("Document successfully updated!");
          console.log(docRef);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      this.$router.push({ name: "MyProfile" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

.v-btn {
  width: auto;
}
li {
  list-style: none;
  list-style-position: inside;
}
ul {
  padding: 0;
}
.list {
  display: flex;
  justify-content: space-between;
}

.input-field-container {
  display: flex;
  flex-direction: row;

  > .add-textfield-style {
    max-width: 50%;
  }
}
.delete,
.pointer-cursor {
  cursor: pointer;
}

.diagnose-text-area {
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
  height: 10em;
}
</style>
