<template>
  <v-container>
    <TopNav />
    <v-row class="flex-column pa-8">
      <h1 class="pb-7">Personopplysninger</h1>
      <v-avatar class="text-center" color="primary" size="120">
        <img v-if="avatar" :src="avatar" width="100" height="100" />
        <v-icon v-else color="secondary">photo_camera</v-icon>
      </v-avatar>

      <input
        class="pt-2"
        type="file"
        id="imageSelector"
        accept="image/*"
        @change="selectPicture"
      />
      <v-col class="pt-7">
        <label>Fullt navn</label>
        <v-text-field
          class="mt-2 pa-0"
          v-model="fullName"
          :placeholder="fullName"
        >
        </v-text-field>
        <label>Fødselsdato*</label>
        <div class="mt-2 pa-0 fancy-underline">
          <input type="date" class="mt-2 pa-0" v-model="birthdate" />
        </div>
        <!-- <v-dialog
          ref="dialog"
          v-model="menu2"
          :close-on-content-click="false"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="properBirthdate"
              readonly
              v-bind="attrs"
              v-on="on"
              placeholder="Legg til dato"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="birthdate"
            scrollable
            @input="setProperDate"
            locale="no-nb"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false"> Avbryt </v-btn>
            
          </v-date-picker>
        </v-dialog> -->
      </v-col>

      <v-col>
        <v-btn
          depressed
          rounded
          outlined
          color="primary"
          class="btn pa-7 primary--text"
          @click="openpp"
          >Personvernerklæring
        </v-btn>
        <PrivacyPolicyDialog
          @closePrivacyPolicy="privacyPolicy = false"
          v-if="privacyPolicy"
        />
      </v-col>
    </v-row>

    <div @click="checkLocalStorage" class="pointer-cursor">
      <v-bottom-navigation
        app
        flat
        :background-color="color"
        class="secondary--text"
        height="80px"
      >
        <v-toolbar-title class="ma-auto subtitle-1 font-weight-bold cursor"
          >Lagre</v-toolbar-title
        >
      </v-bottom-navigation>
    </div>
    <PopupModal
      :saveInfo="savePersonalInfo"
      v-if="displayModal"
      @closeModalFromChild="displayModal = false"
    />
  </v-container>
</template>

<script>
import firebase from "@/firebase/db";
import TopNav from "../components/TopNav.vue";
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.vue";
import PopupModal from "../components/PopupModal.vue";
export default {
  components: {
    TopNav,
    PrivacyPolicyDialog,
    PopupModal,
  },
  data() {
    return {
      displayModal: false,
      privacyPolicy: false,
      avatar: null,
      fullName: "",
      birthdate: "",
      properBirthdate: "",
      menu2: false,
      file: "",
    };
  },
  computed: {
    color() {
      return "primary";
    },
  },
  mounted() {
    this.getPersonalInfo();
    // this.getPicture();
  },
  methods: {
    openpp() {
      this.privacyPolicy = true;
      console.log(this.privacyPolicy);
    },
    test(on) {
      console.log(on);
    },
    getPersonalInfo() {
      var currentUser = firebase.auth().currentUser;
      var docRef = firebase.firestore().collection("user").doc(currentUser.uid);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          this.avatar = doc.data().avatar;
          this.fullName = doc.data().fullName;
          this.birthdate = doc.data().birthdate;
          this.setProperDate();
        } else {
          console.log("No such document!");
        }
      });
    },

    checkLocalStorage() {
      if (JSON.parse(localStorage.getItem("hideDialogLocalStorage"))) {
        this.savePersonalInfo();
      } else {
        this.displayModal = true;
      }
    },

    savePersonalInfo() {
      var currentUser = firebase.auth().currentUser;
      var docRef = firebase.firestore().collection("user").doc(currentUser.uid);
      docRef
        .set(
          {
            avatar: this.avatar ? this.avatar : "",
            fullName: this.fullName ? this.fullName : "",
            birthdate: this.birthdate ? this.birthdate : "",
          },
          { merge: true }
        )
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      console.log(this.avatar);
      this.$router.push({ name: "MyProfile" });
    },
    selectPicture(event) {
      var file = event.target.files[0];
      var currentUser = firebase.auth().currentUser;
      var storageRef = firebase
        .storage()
        .ref(currentUser.uid + "/profilePicture/" + file.name);
      setTimeout(() => {
        storageRef
          .getDownloadURL()
          .then((img) => {
            this.avatar = img;
          })
          .catch(() => {
            this.selectPicture(event);
          });
      }, 1500);
      // kan putte denne i en variabel for å se opplastings detaljer
      storageRef.put(file);
      // var imgDetails = storageRef.put(file);
      // console.log(imgDetails);
    },
    setProperDate() {
      this.menu2 = false;
      let date = this.birthdate;
      if (date != undefined) {
        var datearray = date.split("-");
        this.properBirthdate =
          datearray[2] + "/" + datearray[1] + "/" + datearray[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
img {
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.v-btn {
  width: 100%;
}
.btn {
  background: $secondary;
}
.v-btn::before {
  background: $secondary;
}
.pointer-cursor {
  cursor: pointer;
}

.fancy-underline {
  border-bottom: 1px solid #999999;
}
.fancy-underline:hover {
  border-bottom-color: #000000;
}

// Stella skal se på dette
// |
// v
// .v-menu__content {
// position: fixed;
// max-width: 60%;
// text-align: center;
//   // top: 90px !important;
//   // left: 20px !important;
// }
</style>
