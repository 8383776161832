import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import firebase from "../firebase/db";
import CreateUser from "../views/CreateUser.vue";
import DeleteUser from "../views/DeleteUser.vue";
import MyProfile from "../views/MyProfile.vue";
import PersonalInformation from "../views/PersonalInformation.vue";
import HealthInformation from "../views/HealthInformation.vue";
import LandingPage from "../views/LandingPage.vue";
import ForgotPassword from "../components/ForgottenPassword.vue";
import ActionPage from "../views/ActionPage.vue";
import ErrorPage from "../views/404.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/glemt-password",
      name: "forgotPassword",
      component: ForgotPassword,


    },
    {
      path: "/registrer",
      name: "CreateUser",
      component: CreateUser,
    },
    {
      path: "/u/*",
      name: "LandingPage",
      component: LandingPage,
    },
    {
      path: "/slett-bruker",
      name: "DeleteUser",
      component: DeleteUser,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/min-profil",
      name: "MyProfile",
      component: MyProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/personopplysninger",
      name: "PersonalInformation",
      component: PersonalInformation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/helseopplysninger",
      name: "HealthInformation",
      component: HealthInformation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/__/auth/action",
      name: "ActionPage",
      component: ActionPage,
    },
    {
      path: "/*",
      name: "ErrorPage",
      component: ErrorPage,
    },
  ],
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(function (user) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!user) {
        next({ name: "Home" });
      } else {
        next();
      }
    } else {
      next();
    }
  });
});
export default router;
